import React, { useState } from 'react';
import { Seo } from '@app/components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  FeatureRow,
  Footer,
  Header,
  TryForFree,
  CustomerLogos, CreateAccount
} from '@app/components/template';
import waves from '@app/images/template/waves-shape.svg';
import { AccountData, CreateState } from '@app/model';
import { signUp } from '@app/services';
import { Modal } from 'react-bootstrap';

export default () => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const [state, setState] = useState(CreateState.INITIAL);
  const onSubmit = async (accountData: AccountData) => {
    setState(CreateState.IN_PROGRESS);
    try {
      await signUp(accountData);
      setState(CreateState.SUCCESS);
    } catch (e) {
      setState(CreateState.ERROR);
    }
  };
  const onCancel = () => {
    setShow(false);
    setState(CreateState.INITIAL);
  };
  const showModal = () => {
    setState(CreateState.INITIAL);
    setShow(true);
  };

  const features1 = [
    {
      icon: 'fa fa-hand-pointer-o',
      title: intl.formatMessage({ id: 'features_instant_creation' }),
      description: intl.formatMessage({ id: 'features_instant_creation_desc' })
    },
    {
      icon: 'fa fa-pencil',
      title: intl.formatMessage({ id: 'features_simple_registration' }),
      description: intl.formatMessage({ id: 'features_simple_registration_desc' })
    },
    {
      icon: 'fa fa-paper-plane-o',
      title: intl.formatMessage({ id: 'features_notifications' }),
      description: intl.formatMessage({ id: 'features_notifications_desc' })
    },
    // {
    //   icon: 'fa fa-calculator',
    //   title: intl.formatMessage({id: 'features_payments'}),
    //   description: intl.formatMessage({id: 'features_payments_desc'})
    // },
    {
      icon: 'fa fa-print',
      title: intl.formatMessage({ id: 'features_prints' }),
      description: intl.formatMessage({ id: 'features_prints_desc' })
    },
    // {
    //   icon: 'fa fa-id-card-o',
    //   title: intl.formatMessage({id: 'features_diplomas'}),
    //   description: intl.formatMessage({id: 'features_diplomas_desc'})
    // },
    {
      icon: 'fa fa-music',
      title: intl.formatMessage({ id: 'features_music' }),
      description: intl.formatMessage({ id: 'features_music_desc' })
      // },
      // {
      //   icon: 'fa fa-gavel',
      //   title: intl.formatMessage({id: 'features_judge_module'}),
      //   description: intl.formatMessage({id: 'features_judge_module_desc'})
      // },
      // {
      //   icon: 'fa fa-institution',
      //   title: intl.formatMessage({id: 'features_scrutineer_module'}),
      //   description: intl.formatMessage({id: 'features_scrutineer_module_desc'})
    }
  ];

  const features2 = [
    {
      icon: 'fa fa-clock-o',
      title: intl.formatMessage({ id: 'features_always_up_to_date' }),
      description: intl.formatMessage({ id: 'features_always_up_to_date_desc' })
    },
    {
      icon: 'fa fa-lock',
      title: intl.formatMessage({ id: 'features_performers_data_safe' }),
      description: intl.formatMessage({ id: 'features_performers_data_safe_desc' })
    },
    {
      icon: 'fa fa-line-chart',
      title: intl.formatMessage({ id: 'features_intuitive_dashboard' }),
      description: intl.formatMessage({ id: 'features_intuitive_dashboard_desc' })
    },
    {
      icon: 'fa fa-support',
      title: intl.formatMessage({ id: 'features_all_day_support' }),
      description: intl.formatMessage({ id: 'features_all_day_support_desc' })
    }
  ];

  return (
    <>
      <Seo title={ intl.formatMessage({ id: 'seo_title_features' }) }
           description={ intl.formatMessage({ id: 'seo_description_second' }) } />
      <div id="main" className="main-content-wraper">
        <div className="main-content-inner">
          <Header />

          <section id="hero" className="section-lg section-hero section-circle">
            <div className="shape shape-style-1 shape-primary">
              <span className="circle-150"></span>
              <span className="circle-50"></span>
              <span className="circle-50"></span>
              <span className="circle-75"></span>
              <span className="circle-100"></span>
              <span className="circle-75"></span>
              <span className="circle-50"></span>
              <span className="circle-100"></span>
              <span className="circle-50"></span>
              <span className="circle-100"></span>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="hero-content-wrap">
                    <div className="hero-content text-center">
                      <h1 className='text-uppercase'>
                        <FormattedMessage id="features_title" />
                      </h1>
                      <p>
                        <FormattedMessage id="features_subtitle" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-shape">
              <img src={ waves } alt="shape image" />
            </div>
          </section>

          <section id="almost-hero" className="ptb-90">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading text-center">
                    <h3>
                      <FormattedMessage id="features_see_how_can_help" />
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                {
                  features1.map((feature, i) => (
                    <FeatureRow key={ i } feature={ feature } />
                  ))
                }
              </div>
            </div>
          </section>

          <section className="bg-secondary ptb-90">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading text-center">
                    <h3>
                      <FormattedMessage id="features_title" />
                      <br />
                      <FormattedMessage id="features_security" />
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                {
                  features2.map((feature, i) => (
                    <FeatureRow key={ i } feature={ feature } />
                  ))
                }
              </div>
            </div>
          </section>

          <section id="almost-hero" className="ptb-90">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading text-center" style={ { marginBottom: '0' } }>
                    <h3>
                      <FormattedMessage id="features_there_is_more" />
                    </h3>
                    <p>
                      <FormattedMessage id="features_there_is_more_desc_first" />
                      <a href="https://tanecznykalendarz.pl" target='_blank'>TANECZNYKALENDARZ.pl</a>.
                      <FormattedMessage id="features_there_is_more_desc_second" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ptb-90">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading text-center">
                    <h3>
                      <FormattedMessage id="features_checked_by_many" />
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <CustomerLogos />
                </div>
              </div>
            </div>
          </section>

          <TryForFree buttonClick={ () => showModal() }
                      title={ intl.formatMessage({ id: 'features_you_better_check' }) }
                      description={ intl.formatMessage({ id: 'features_create_account_and_test' }) } />

          <Footer />

          <Modal show={ show } onHide={ () => setShow(false) }>
            <CreateAccount state={ state }
                           submit={ (payload: AccountData) => onSubmit(payload) }
                           cancel={ () => onCancel() } />
          </Modal>
        </div>
      </div>
    </>
  );
};